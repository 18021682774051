<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in users"
        :key="index")
        .list-1
          .list-1__wrapper
            .list-1__left
              .list-1__title.list-1__title--medium {{ item.first_name }}
              .list-1__item
                .list-1__item-label {{ item.group_key | groupName | translate }}
            .list-1__right
              .list-1__settings
                v-btn(
                icon
                @click="openEdit(item)")
                  icon-gear.list-1__icon

      v-table-body(
        v-if="!loading"
        outside-table
        :empty="!users.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 1" :key="`${i}-${j}`")
            .list-1__item-skeleton

    v-dialog(
      max-width="348"
      v-model="editModal.show")
      settings-modal(
        :item="editModal.item"
        @close="editModal.show = false")

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { USER_TYPES } from '@/util/consts.js'

import SettingsModal from './modals/SettingsModal'

import IconGear from '@/components/icons/IconGear'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'EmployeesTableMobile',

  mixins: [paginationMixin],

  components: {
    IconGear,
    SettingsModal
  },

  data: () => ({
    editModal: {
      item: null,
      show: false
    }
  }),

  mounted () {
    this.setupPagination(this.fetchUsers)
  },

  methods: {
    ...mapActions(['fetchUsers']),

    openEdit (item) {
      this.editModal.item = item
      this.editModal.show = true
    }
  },

  filters: {
    groupName (value) {
      return USER_TYPES[value]
    }
  },

  computed: {
    ...mapGetters([
      'users'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
